/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import { TypeDhlFooter } from './TypeDhlFooter';
import { device } from '../../styles/Device';
import DhlText from '../DhlText';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DhlImage } from '@dhl-official/react-library';
import DHLLogo from '../../assets/images/DHLGroupLogo_black.svg';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  height: 140px;
  display: flex;
  flex-direction: row;
  padding: ${(props) => props.theme.spacing.lg};
  @media ${device.mobile} {
    flex-wrap: wrap;
  }
  @media ${device.tablet} {
    flex-wrap: nowrap;
  }
`;

const MetaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media ${device.mobile} {
    justify-content: center;
  }
  @media ${device.tablet} {
    justify-content: flex-start;
  }
  margin-top: ${(props) => props.theme.spacing.sm};
  margin-bottom: ${(props) => props.theme.spacing.sm};
`;

const MetabButton = styled.div`
  display: flex;
  flex-direction: row;
  text-decoration: underline #333333;
  margin-right: ${(props) => props.theme.spacing.lg};
  padding: ${(props) => props.theme.spacing.xs};
  cursor: pointer;
  font-size: 12px;
  color: '#333333';
`;

const MetabButtonA = styled.a`
  display: flex;
  flex-direction: row;
  text-decoration: underline #333333;
  margin-right: ${(props) => props.theme.spacing.lg};
  padding: ${(props) => props.theme.spacing.xs};
  cursor: pointer;
  font-size: 12px;
  color: '#333333';
`;

const LeftWrapper = styled.div`
  @media ${device.mobile} {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: ${(props) => props.theme.spacing.xs};
  }
  @media ${device.tablet} {
    width: 50%;
    align-items: flex-start;
    justify-content: center;
    margin-left: ${(props) => props.theme.spacing.xxl};
  }
  display: flex;
  flex-direction: column;
`;

const RightWrapper = styled.div`
  @media ${device.mobile} {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: ${(props) => props.theme.spacing.xs};
  }
  @media ${device.tablet} {
    width: 50%;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: ${(props) => props.theme.spacing.xxl};
  }
  display: flex;
  flex-direction: column;
`;

const DhlLogo = styled(DhlImage)`
  position: relative;
  width: 169px;
`;

const DhlFooter: React.FC<TypeDhlFooter> = (props) => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { metaData, copyright, versionData } = props;

  return (
    <Wrapper theme={theme}>
      <LeftWrapper theme={theme}>
        <DhlLogo src={DHLLogo} alt="" />

        <MetaWrapper theme={theme}>
          {metaData?.map((metaDetail) =>
            metaDetail.label === 'App.footer.CookieSettings' ? (
              <MetabButtonA
                theme={theme}
                key={metaDetail.label}
                href={metaDetail.href}
              >
                <DhlText
                  size="sm"
                  color={'#333333'}
                  weight={200}
                  title={t(metaDetail.label)}
                />
              </MetabButtonA>
            ) : (
              <MetabButton
                data-tracking={metaDetail.dataTracking}
                theme={theme}
                key={metaDetail.label}
                onClick={() => {
                  if (metaDetail.external)
                    window.open(metaDetail.href, '_blank');
                  else navigate(metaDetail.href);
                }}
              >
                <DhlText
                  size="sm"
                  color={'#333333'}
                  weight={200}
                  title={t(metaDetail.label)}
                />
              </MetabButton>
            )
          )}
        </MetaWrapper>

        <DhlText
          size="sm"
          color={'#333333'}
          weight={200}
          title={`${t('App.footer.applicationVersion')} ${
            versionData.version
          } - ${t('App.footer.releaseDate')} ${versionData.releaseDate}`}
        />
      </LeftWrapper>

      <RightWrapper theme={theme}>
        <DhlText
          size="sm"
          color={'#333333'}
          weight={200}
          title={t(copyright)}
        />
      </RightWrapper>
    </Wrapper>
  );
};

export default DhlFooter;
