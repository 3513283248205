import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {
    // DhlFooter,
    DhlLoader,
    DhlModal,
} from '@dhl-official/react-library';
import {PATHS, metaData, versionData} from './constants';
import {ThemeContext} from '../../styles/ThemeContext';
import Header from '../../components/Header';
import '../../Language/i18next';
import {LoadingContext} from '../../contexts/LoadingContext';
import {
    //  NOTIFICATION_TYPES,
    NotificationContext,
} from '../../contexts/NotificationContext';
import DhlSnackbar from '../../components/DhlSnackbar';
import {isEmpty} from '../../utils';
import './App.css';
// import Home from '../Home';
import Error from '../ErrorPage';
// import Externaluser from '../ExternalUser';
// import Customers from '../Customers';
// import CoList from "../CoList";
// import Customer from '../Customer';
// import CoPage from "../CoPage";
// import ProfilePage from '../ProfilePage';
// import UploadUser from '../UploadUser';
// import PrivacyPage from '../PrivacyPage';
import DhlFooter from '../../components/DhlFooter';
// import Brandings from '../Brandings';
// import Branding from '../Branding';
// import AppListPage from '../AppListPage';
import ScrollToTop from '../../components/ScrollToTop';
// import NewsListPage from '../NewsListPage';
// import NewsListUpdatePage from '../NewsListUpdatePage';
import {JSX} from 'react/jsx-runtime';
import withAdobeAnalytics from '../../components/Adobe';
// import Categories from '../Categories';
// import Category from '../Category';
// import UsersPage from "../UsersPage";
import { ThemeType } from '../../styles/types/ThemeType';
import { UserContext } from '../../contexts/UserContext';
import { device } from '../../styles/Device';
import { LazyRouteLoader, MSCRoutes } from './LazyRouteLoader';

const ImpersonationWrapper = styled.div<{ theme: ThemeType; impersonated: boolean }>`
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${(props) => props.impersonated ? props.theme.color.red[500] : props.theme.secondaryBg};
  align-items: center;
`;

const AppWrapper =  styled.div<{ theme: ThemeType; impersonated: boolean }>`
  @media ${device.mobile} {
    width: ${(props) => props.impersonated ? '98%' : '100%'};
  }
  @media ${device.tablet} {
    width: ${(props) => props.impersonated ? '99%' : '100%'};
  }  
  @media ${device.laptop} {
    width: ${(props) => props.impersonated ? '99%' : '100%'};
  }    
  @media ${device.desktop} {
    width: ${(props) => props.impersonated ? '99.5%' : '100%'};
  }  
  @media ${device.largeDesktop} {
    width: ${(props) => props.impersonated ? '99.5%' : '100%'};
  }
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${(props) => props.theme.secondaryBg};
`;

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.grey[50]};
`;

const MainContentWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 180px);
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  height: 100%;
`;

const BottomWrapper = styled.div`
  width: 100%;
  // min-height: calc(100vh - 60px);
  overflow: auto;
  display: block;
  position: relative;
  flex-direction: column;
  background-color: ${(props) => props.theme.secondaryBg};
`;

function App(): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const { user } = useContext(UserContext);
    const {isLoading, isUserLoading, loadings} = useContext(LoadingContext);

    const {type, message, setType, setMessage} =
        useContext(NotificationContext);
    const [isNotificationShowing, setIsNotificationShowing] = useState(false);

    useEffect(() => {
        if (!isEmpty(type) && !isEmpty(message)) setIsNotificationShowing(true);
    }, [type, message]);

    console.log("THEME ==> ", theme);
    console.log("IS LOADINGS ==> ", isLoading);
    console.log("LOADINGS ==> ", loadings);

    // Show Error, Wearning or Success messages.
    useEffect(() => {
        if (isNotificationShowing)
            setTimeout(() => {
                setIsNotificationShowing(false);
                setType('');
                setMessage('');
            }, 3000);
    }, [isNotificationShowing]);

    const content = (): React.JSX.Element =>
        (
        <BrowserRouter>
            <ScrollToTop/>
            <AppWrapper theme={theme} impersonated={user?.impersonated ?? false}>
                <Header/>

                <BottomWrapper theme={theme}>
                    <MainContentWrapper>
                        <ContentWrapper theme={theme}>
                            <Routes>
                                <Route path="/" element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.Home} />)}/>
                                <Route path={PATHS.ERROR} element={withAdobeAnalytics(<Error/>)}/>
                                <Route path={PATHS.INACTIVE} element={withAdobeAnalytics(<Error/>)}/>
                                <Route path={PATHS.EXPIRED} element={withAdobeAnalytics(<Error/>)}/>
                                <Route path={PATHS.NOT_FOUND} element={withAdobeAnalytics(<Error/>)}/>
                                <Route
                                    path={PATHS.EXTERNAL_USER}
                                    element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.ExternalUser} />)}/>
                                <Route path={PATHS.UPLOAD_USER} element={<LazyRouteLoader name={MSCRoutes.UploadUser} />}/>
                                <Route path={PATHS.CUSTOMERS} element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.CoList} />)}/>
                                <Route path={PATHS.CUSTOMER_DETAIL} element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.CoPage} />)}/>
                                {/* <Route path={PATHS.CUSTOMER_ADD} element={withAdobeAnalytics(<Customer/>)}/> */}
                                <Route path={PATHS.CUSTOMER_ADD} element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.CoPage} />)}/>
                                <Route path={PATHS.PROFILE} element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.ProfilePage} />)}/>
                                <Route path={PATHS.PRIVACY_NOTICE} element={<LazyRouteLoader name={MSCRoutes.PrivacyPage} />}/>
                                <Route path={PATHS.LOGOUT} element={withAdobeAnalytics(<Error/>)}/>
                                <Route path={PATHS.BRANDING_LIST} element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.Brandings} />)}/>
                                <Route path={PATHS.APP_DETAILS} element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.AppListPage} />)}/>
                                <Route path={PATHS.CATEGORIES} element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.Categories} />)}/>
                                <Route path={PATHS.CATEGORIES_DETAIL_DETAIL} element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.Category} />)}/>
                                <Route path={PATHS.CATEGORIES_ADD} element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.Category} />)}/>
                                <Route
                                    path={PATHS.BRANDING_OBJECT_DETAIL_DETAIL}
                                    element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.Branding} />)}
                                />
                                <Route
                                    path={PATHS.BRANDING_OBJECT_ADD}
                                    element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.Branding} />)}
                                />
                                <Route
                                    path={PATHS.NEWS_LIST}
                                    element={<LazyRouteLoader name={MSCRoutes.NewsListPage} />}/>
                                <Route
                                    path={PATHS.NEWS_ADD} element={<LazyRouteLoader name={MSCRoutes.NewsListUpdatePage} />}/>
                                <Route
                                    path={PATHS.NEWS_EDIT}
                                    element={<LazyRouteLoader name={MSCRoutes.NewsListUpdatePage} />}
                                />
                                <Route path={PATHS.USERS} element={withAdobeAnalytics(<LazyRouteLoader name={MSCRoutes.UsersPage} />)}/>
                            </Routes>
                        </ContentWrapper>
                    </MainContentWrapper>

                    <FooterWrapper theme={theme}>
                        <DhlFooter
                            versionData={versionData}
                            metaData={metaData}
                            copyright="App.footer.rights"
                        />
                    </FooterWrapper>
                </BottomWrapper>

                <DhlModal
                    shouldShowCloseButton={false}
                    isOpen={isLoading || isUserLoading || loadings > 0}
                    trapFocus={true}
                    dataId="my-loading-dialog"
                    dataClassName="modelView"
                >
                    <DhlLoader size="sm"/>
                </DhlModal>

                {isNotificationShowing && <DhlSnackbar type={type} message={message}/>}
            </AppWrapper>
        </BrowserRouter>
    );
    if (user?.impersonated === true) {
        return (<ImpersonationWrapper theme={theme} impersonated={user?.impersonated ?? false}>
                    {content()}
                </ImpersonationWrapper>)
    } else {
        return (content());
    }
}

export default App;
