import React, { createContext, useState, useEffect, useContext } from 'react';
import CommonType from './types/CommonType';
import ServiceRequest from '../service/ServiceRequest';
import { NOTIFICATION_TYPES, NotificationContext } from './NotificationContext';
import { LoadingContext } from './LoadingContext';
import LanguageType from './types/LangugaeType';
import { LocalStorageHelper } from '../utils/LocalStorageHelper';

const LOCAL_STORAGE_LANGUAGES = 'LOCAL_STORAGE_LANGUAGES';

export const LanguageContext = createContext({
  languages: [] as LanguageType[],
});

const LanguageProvider: React.FC<CommonType> = (props) => {
  const [languages, setLanguagesData] = useState<LanguageType[]>([]);
  const { setType, setMessage } = useContext(NotificationContext);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(true);
    const localLanguages = LocalStorageHelper.getLocalResponseData(LOCAL_STORAGE_LANGUAGES);

    if (localLanguages !== null) {
      setLanguagesData(localLanguages.data);
      setLoading(false);
    } else {
      ServiceRequest.getLanguages()
      .then((response) => {
        setLanguagesData(response.data);
        LocalStorageHelper.setLocalResponseData(LOCAL_STORAGE_LANGUAGES, response);
      })
      .catch((error) => {
        setType(NOTIFICATION_TYPES.FAILED);
        setMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ languages }}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
