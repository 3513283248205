/* eslint-disable @typescript-eslint/no-extraneous-class */
import { AxiosResponse } from 'axios';

type UnitOfTime = 'minutes' | 'hours' | 'days';

interface LocalStorageAxiosResponse<T> {
  response: AxiosResponse<T>;
  expiresOn: Date;
}

export class LocalStorageHelper {
  static getLocalResponseData(localStorageKey: string): AxiosResponse<any> | null {
    try {
      const localData = localStorage.getItem(localStorageKey);

      if (localData === null) {
        return null;
      }

      const localResponse = JSON.parse(localData) as LocalStorageAxiosResponse<any>;

      return new Date().getTime() > new Date(localResponse.expiresOn).getTime() ? null : localResponse.response;
    } catch {
      localStorage.removeItem(localStorageKey);
      return null;
    }
  }

  static setLocalResponseData(
    localStorageKey: string,
    response: AxiosResponse<any>,
    cacheValue: number = 1,
    cacheUnitOfTime: UnitOfTime = 'days'
  ): void {
    const expiresOn = LocalStorageHelper.addTime(new Date(), cacheValue, cacheUnitOfTime);
    localStorage.setItem(localStorageKey, JSON.stringify({ response, expiresOn }));
  }

  private static addTime(date: Date, value: number, unit: UnitOfTime): Date {
    return new Date(date.getTime() + LocalStorageHelper.convertToMillis(value, unit));
  }

  private static convertToMillis(value: number, unit: UnitOfTime): number {
    switch (unit) {
      case 'minutes': {
        return value * 60 * 1000;
      }
      case 'hours': {
        return value * 60 * 60 * 1000;
      }
      case 'days': {
        return value * 24 * 60 * 60 * 1000;
      }
      default:
        return 0;
    }
  }
}
