import React, {useContext} from 'react';
import UserType from "../../contexts/types/UserType";
import {UserContext} from '../../contexts/UserContext';

const onUserLoad = (user: UserType): void => {
    // @ts-expect-error
    if (dataLayer?.push != null && user != null && user.email != null) {
        // console.info("adobe pushing: ", window.location.pathname, user.countryIso );
        const index = user.email.indexOf('@');
        // @ts-expect-error
        dataLayer.push({
            event: "contextChange", context: {
                page: {
                    pageInfo: {
                        pageName: window.location.pathname === '/profile' ? 'User Profile' : 'MySupplyChain',
                        language: user.languageCode,
                        publisher: "MSC",
                        country: user.countryIso
                    }
                },
                user: {
                    account: index > -1 ? user.email.substring(index + 1) : null
                }
            }
        });
    }
};

const withAdobeAnalytics = (children: any): JSX.Element => {
    const {user} = useContext(UserContext);
    onUserLoad(user);
    return <div>{children}</div>;

}

export default withAdobeAnalytics;
