import React, { useContext } from 'react';
import { TypeIcon } from './TypeIcon';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
// import { DhlImage } from '@dhl-official/react-library';
// import { IconContext } from '../../assets/IconContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ItemWrapper = styled.div`
  width: auto !important;
  width: 100%;
  max-height: 50px;
  display: flex;
  margin-top: 4px;
  padding: 8px;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.theme.isHeader === true ? '' : '#f9f9f9')};
    color: ${(props) => (props.theme.isHeader === true ? '' : '#d40511')};
  }
`;

const LeftIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: end;
`;

const TextWrapper = styled.div`
  width: auto !important;
  display: flex;
  margin-top: 4px;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const StyledText = styled.text`
  font-size: 13px;
  font-weight: ${(props) => (props.theme.isHeader === true ? 600 : 400)};
  margin-top: 0px;
  margin-bottom: 0px;
`;

const MenuItemApp: React.FC<TypeIcon> = (props) => {
  const theme = useContext(ThemeContext);
  // const { getIcon } = useContext(IconContext);
  const { label, onSelect, icon, isHeader = false } = props;

  const onClick = (e: any): void => {
    if (onSelect !== undefined) onSelect();
  };

  return (
    <ItemWrapper theme={{ ...theme, isHeader }} onClick={onClick}>
      <LeftIconWrapper>
        <FontAwesomeIcon icon={icon} />
        {/* <LeftIcon alt={label} id={label} src={getIcon(icon)} /> */}
      </LeftIconWrapper>

      <TextWrapper>
        <StyledText theme={{ ...theme, isHeader }}>{label}</StyledText>
      </TextWrapper>
    </ItemWrapper>
  );
};

export default MenuItemApp;
