/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import React, { useContext, useEffect, useState } from 'react';
import {
  DhlAccordion,
  DhlIcon,
  DhlIconWrapper,
  DhlImage,
  DhlModal,
  DhlPanel,
} from '@dhl-official/react-library';
import DHLLogo from '../../assets/images/dhl-logo.svg';
import './Header.css';
import { ThemeContext } from '../../styles/ThemeContext';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DhlText from '../DhlText';
// import { UserContext } from '../../contexts/UserContext';
import { IconContext } from '../../assets/IconContext';
// import component
import Drawer from 'react-modern-drawer';
// import styles
import 'react-modern-drawer/dist/index.css';
import MenuItem from '../ProfileContext/MenuItem';
import { UserContext } from '../../contexts/UserContext';
import BrandingHeader from './BrandingHeader';
import { getBackUrl, logout, validateDHLEmailDomain } from '../../utils';
// import MenuAccessibility from '../AccessibilityContext/MenuAccesibility';
import { LanguageContext } from '../../contexts/LanguageContext';
import MenuItemLanguage from '../MenuContext/MenuItem';
import { iconTypeEnum } from '../MenuContext';
import MenuItemApp from '../AppContenxt/MenuItem';
import { useNavigate } from 'react-router-dom';
import DhlButton from '../DhlButton';
import { isEmpty } from 'lodash';
import { Scrollbar } from 'smooth-scrollbar-react';
import { PATHS } from '../../pages/App/constants';
import { NOTIFICATION_TYPES, NotificationContext } from '../../contexts/NotificationContext';
import { getAccessToken } from "../../service";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

const Ribbon = styled.div`
  width: 150px;
  background-color: ${(props) => props.theme.color.red[400]};
  text-align: center;
  color: ${(props) => props.theme.color.white[400]};
  transform: rotate(-45deg);
  margin-left: -50px;
  padding: 5px;
  z-index: 2;
`;

const LeftWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const RightWrapper = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
`;

const DrawerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 8px;
  flex-direction: column;
  background: linear-gradient(
    0deg,
    #ffcc00 10%,
    #ffcc00 30%,
    #ffe57f 70%,
    #fff0b2
  );
`;

const VerticalDevider = styled.div`
  width: 2px;
  height: 70%;
  margin-left: 20px;
  background-color: #ababab;
`;

const TopLaypout = styled.div`
  width: 100%;
  height: 70px;
  padding: 8px;
`;

const LanguageHeader = styled(DhlText)`
  width: 100%;
  margin-bottom: 10px;
`;

const MyCustomButton = styled.div`
  width: 100%;
  height: 70px;
  padding: 8px;
  display: flex;
  align-items: center;
  font-size: larger;
  font-weight: 900;
  border-bottom: 1px solid ${(props) => props.theme.color.grey[500]};
  text-shadow: 1px 0 #000000;
  cursor: pointer;
`;

const BrandingLaypout = styled.div`
  width: 100%;
  height: 70px;
  padding: 8px;
  background-color: ${(props) => props.theme.color.grey[100]};
`;

const LanguageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.primaryText};
  text-align: center;
`;

function SideDrawer({selectedLanguage, setImpersonatorModal}: any): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { getIcon } = useContext(IconContext);
  const { isLogin, user } = useContext(UserContext);
  const { languages } = useContext(LanguageContext);
  const {setType, setMessage} = useContext(NotificationContext);

  const isBrandingObjectShowing =
    !!user.brandingObject &&
    !!user.brandingObject.homepageImage &&
    user.brandingObject.stylesheetId === 2;

  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [isAccessibilityOpen, setIsAccessibilityOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    void i18n.changeLanguage(selectedLanguage);
  }, []);

  const toggleMenu = (): void => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const onClickAccessibility = (): void => {
  //   toggleMenu();
  //   setIsAccessibilityOpen(true);
  // };

  const onClickLangugage = (): void => {
    toggleMenu();
    setIsLanguageOpen(true);
  };

  const onClickLogout = (): void => {
    toggleMenu();
    logout();
  };

  const onLanguageChange = (item: any): void => {
    localStorage.setItem(`MSC-${process.env.REACT_APP_ENVIRONMENT}-language`, item.code);
    void i18n.changeLanguage(item.code);
    setIsLanguageOpen(false);
  };

    const { refreshUser } = useContext(UserContext);
    const login: () => void = () => {
        // Just obtain the token, the page should be then refreshed by its own.
        getAccessToken()
            .then(
                (accessToken) => {
                    refreshUser(false)
                }
            )
            .catch(
                err => console.error(err)
            );
    }

  return (
    <Wrapper>
      <LeftWrapper theme={theme}>
        {!isEmpty(process.env.REACT_APP_ENVIRONMENT) && (
          <Ribbon theme={theme}>{process.env.REACT_APP_ENVIRONMENT}</Ribbon>
        )}

        <DhlImage src={DHLLogo} alt="" />

        <VerticalDevider />

        <DhlText
          size="sm"
          weight={600}
          color="#eb131e"
          title={t('App.applicationName')}
        />
      </LeftWrapper>

      <RightWrapper theme={theme}>
        <DhlIconWrapper size="lg" onClick={toggleMenu}>
          <DhlIcon src={getIcon(isMenuOpen ? 'cross' : 'sideMenu')} />
        </DhlIconWrapper>
      </RightWrapper>

      <Drawer open={isMenuOpen} onClose={toggleMenu} direction="left">
        {!isLogin && (
          <DrawerWrapper>
            <DhlButton
              isBlock
              size="md"
              title={t('App.components.Header.login')}
              onClick={() => login()}
            />
          </DrawerWrapper>
        )}

        {isLogin && (
          <Scrollbar>
            <DrawerWrapper>
              {isBrandingObjectShowing && (
                <BrandingLaypout theme={theme}>
                  <BrandingHeader />
                </BrandingLaypout>
              )}
              <TopLaypout>
                <MenuItem
                  label={`${user.firstName}`}
                  type="options"
                  role={user?.roles.length > 0 ? user?.roles[0] : ""}
                  icon="fa-user-circle"
                />
              </TopLaypout>

              <DhlAccordion>
                <DhlPanel>
                  <span slot="heading">{t('App.page.SideDrawer.apps')}</span>

                  {user.apps?.map((appItem) => {
                    if (appItem.list === true) {
                      return (
                        <MenuItemApp
                          key={`menu-item ${appItem.id}`}
                          label={`${appItem.name}`}
                          onSelect={() => {
                            toggleMenu();
                            window.open(
                              `${process.env.REACT_APP_BASE_URL}/app/${appItem.id}`
                            );
                          }}
                          icon={appItem.icon}
                        />
                      );
                    } else if (
                      appItem.childApps !== null &&
                      appItem.childApps?.length > 0
                    ) {
                      return appItem.childApps?.map((childAppItem) => (
                        <MenuItemApp
                          key={`menu-item ${childAppItem.id}`}
                          label={`${childAppItem.name}`}
                          onSelect={() => {
                            toggleMenu();
                            user?.impersonated !== undefined && user?.impersonated ? window.open(childAppItem.link, '_self') : window.open(childAppItem.link) // to keep impersonation session, open the link as _self
                          }}
                          icon={childAppItem.icon}
                        />
                      ));
                    } else {
                      return (
                        <MenuItemApp
                          key={`menu-item ${appItem.id}`}
                          label={`${appItem.name}`}
                          onSelect={() => {
                            toggleMenu();
                            user?.impersonated !== undefined && user?.impersonated ? window.open(appItem.link, '_self') : window.open(appItem.link) // to keep impersonation session, open the link as _self
                          }}
                          icon={appItem.icon}
                        />
                      );
                    }
                  })}
                </DhlPanel>
                <DhlPanel>
                  <span slot="heading">{t('App.page.Home.myOptions')}</span>
                  <MenuItemApp
                    label={t('App.page.Home.profile')}
                    onSelect={() => {
                      toggleMenu();
                      navigate(PATHS.PROFILE);
                    }}
                    icon="fa-user"
                  />
                </DhlPanel>
                <DhlPanel>
                  <span slot="heading">{t('App.page.Home.adminOptions')}</span>
                  {user.adminMenuLinks && user.adminMenuLinks.map((menuItem) => (
                    <MenuItemApp
                      key={menuItem.name}
                      label={menuItem.name}
                      onSelect={() => {
                        toggleMenu();
                        if (menuItem.name === 'Create External User') {
                          navigate('/externalUser');
                        } else if (menuItem.name === 'Users' || menuItem.name === 'Groups' || menuItem.name === 'Templates' || menuItem.name === 'Business Functions') {
                          if(user?.impersonated) {
                            toggleMenu();
                            // raf apps are not allowed in impersonation mode
                            setType(NOTIFICATION_TYPES.FAILED);
                            setMessage(t('App.page.Impersonator.impersonation.notAllowedInImpersonationMode'))
                          } else {
                            window.open(
                              `${getBackUrl()}/${process.env.REACT_APP_MSC_PATH}${
                                menuItem.url
                              }`
                            );
                          }
                        } else{
                          navigate(menuItem.url);
                        }
                      }}
                      icon={menuItem.icon}
                    />
                  ))}

                  { ((user?.impersonator && validateDHLEmailDomain(user?.email)) ?? false ) &&
                    (
                      <MenuItemApp
                        label={t('App.page.Home.impersonator')}
                        key={'App.page.Home.impersonator.mobile.menu'}
                        // url={undefined}
                        icon={'fas fa-people-arrows'}
                        onSelect={() => {
                          toggleMenu();
                          setImpersonatorModal(true);
                        }}
                      />
                    )
                  }
                </DhlPanel>
              </DhlAccordion>

              {/* <MyCustomButton theme={theme} onClick={onClickAccessibility}>
              {t('App.components.Header.accessibility')}
            </MyCustomButton> */}

              <MyCustomButton theme={theme} onClick={onClickLangugage}>
                {t('App.components.Header.language')}
              </MyCustomButton>

              <MyCustomButton theme={theme} onClick={onClickLogout}>
                {t('App.page.Home.logout')}
              </MyCustomButton>
            </DrawerWrapper>
          </Scrollbar>
        )}
      </Drawer>

      {/* <DhlModal
        isOpen={isAccessibilityOpen}
        shouldShowCloseButton={false}
        handleOnClose={() => setIsAccessibilityOpen(false)}
      >
        <MenuAccessibility />
      </DhlModal> */}

      <DhlModal
        isOpen={isLanguageOpen}
        shouldShowCloseButton={false}
        handleOnClose={() => setIsLanguageOpen(false)}
      >
        <LanguageWrapper>
          <LanguageHeader title="select Language" size="md" weight={600} />
          {languages.map((lang: any, index: number) => (
            <MenuItemLanguage
              key={`icon-item-${index}`}
              label={lang.name}
              icon={lang.icon}
              onSelect={onLanguageChange}
              item={lang}
              iconType={iconTypeEnum.BASE64}
              type="options"
            />
          ))}
        </LanguageWrapper>
      </DhlModal>
    </Wrapper>
  );
}

export default SideDrawer;
