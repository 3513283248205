import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import ErrorComponent from './components/ErrorComponent';
import { errorMap } from './constants';
import DhlText from '../../components/DhlText';
import { useTranslation } from 'react-i18next';
import ServiceRequest from '../../service/ServiceRequest';
import { LoadingContext } from '../../contexts/LoadingContext';
import {
  NOTIFICATION_TYPES,
  NotificationContext,
} from '../../contexts/NotificationContext';
import { isEmpty } from '../../utils';
import { useNavigate } from "react-router-dom";
import {PATHS} from "../App/constants";
import {getAccessToken} from "../../service";
import {UserContext} from "../../contexts/UserContext";
// import { useNavigate } from 'react-router-dom';

const PageWrapper = styled.div`
  min-height: calc(100vh - 180px);
  padding: 16px;
  background-color: ${(props) => props.theme.secondaryBg};
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background};
`;

const HintText = styled(DhlText)`
  margin-left: 25px;
`;

const RETURN_TO_HOMEPAGE_DELAY = 5000;

function Error(): JSX.Element {
  const theme = useContext(ThemeContext);
  const { setLoading } = useContext(LoadingContext);
  const { setType, setMessage } = useContext(NotificationContext);
  const { t } = useTranslation();
  const [errorData, setErrorData] = useState(errorMap['1']);
  const [tokenData, setTokenData] = useState('');
  const navigate = useNavigate();
  const { refreshUser } = useContext(UserContext);

  useEffect(() => {
    switch (window.location.pathname) {
      case '/expired':
        setErrorData(errorMap['3']);
        break;

      case '/inactive':
        setErrorData(errorMap['1']);
        break;

      case PATHS.NOT_FOUND:
        setErrorData(errorMap['4']);
        break;

      case '/loggedOut':
        setErrorData(errorMap['3']);
        setTimeout(() => navigate("/") , RETURN_TO_HOMEPAGE_DELAY);
        break;

      default:
        setErrorData(errorMap['4']);
        break;
    }

    const str = 'token=';
    const a = window.location.href.indexOf(str);
    let token;
    if (a !== -1) {
      token = window.location.href.substring(a + str.length);
    }
    setTokenData(token !== undefined ? token : '');
  }, []);

    const login: () => void = () => {
        // Just obtain the token, the page should be then refreshed by its own.
        getAccessToken()
            .then(
                (accessToken) => {
                    refreshUser(false)
                }
            )
            .catch(
                err => console.error(err)
            );
    }

  const handlePrimaySubmit = (): void => {
    if (errorData.id === 'deactivate' && !isEmpty(tokenData)) {
      setLoading(true);
      ServiceRequest.activateUser(tokenData)
        .then((_) => {
          setErrorData(errorMap['2']);
        })
        .catch((error) => {
          // console.error('ERROR ', error);
          setType(NOTIFICATION_TYPES.FAILED);
          setMessage(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (errorData.id === 'activate') {
      return login();
    }

    if (errorData.id === 'logout') {
      return login();
    }

    if (errorData.id === 'notFound') {
      return navigate(PATHS.HOME);
    }
  };

  return (
    <PageWrapper theme={theme}>
      <ErrorComponent
        errorData={errorData}
        onClickPrimaryButton={handlePrimaySubmit}
      />
      <HintText
        size="xs"
        weight={400}
        title={t('App.common.connectDhlRepresentative')}
      />
    </PageWrapper>
  );
}

export default Error;
