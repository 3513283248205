import React, { lazy } from 'react'

export enum MSCRoutes {
  Home = 'Home',
  ExternalUser = 'ExternalUser',
  UploadUser = 'UploadUser',
  CoList = 'CoList',
  CoPage = 'CoPage',
  ProfilePage = 'ProfilePage',
  PrivacyPage = 'PrivacyPage',
  Brandings = 'Brandings',
  Branding = 'Branding',
  AppListPage = 'AppListPage',
  Categories = 'Categories',
  Category = 'Category',
  NewsListPage = 'NewsListPage',
  NewsListUpdatePage = 'NewsListUpdatePage',
  UsersPage = 'UsersPage'
}

type RouteNameToComponentResolver = {
  [P in MSCRoutes]: React.LazyExoticComponent<() => JSX.Element>
}

interface RouteLoaderProps {
  name: MSCRoutes
}

const AllRoutes: RouteNameToComponentResolver = {
  Home: lazy(async () => await import('../Home')),
  ExternalUser: lazy(async () => await import('../ExternalUser')),
  UploadUser: lazy(async () => await import('../UploadUser')),
  CoList: lazy(async () => await import('../CoList')), 
  CoPage: lazy(async () => await import('../CoPage')), 
  ProfilePage: lazy(async () => await import('../ProfilePage')), 
  PrivacyPage: lazy(async () => await import('../PrivacyPage')), 
  Brandings: lazy(async () => await import('../Brandings')), 
  Branding: lazy(async () => await import('../Branding')), 
  AppListPage: lazy(async () => await import('../AppListPage')), 
  Categories: lazy(async () => await import('../Categories')), 
  Category: lazy(async () => await import('../Category')), 
  NewsListPage: lazy(async () => await import('../NewsListPage')), 
  NewsListUpdatePage: lazy(async () => await import('../NewsListUpdatePage')), 
  UsersPage: lazy(async () => await import('../UsersPage')), 
}

export const LazyRouteLoader = (props: RouteLoaderProps): JSX.Element => {
  const LazyRoute = AllRoutes[props.name]

  return (
    <React.Suspense fallback={(<></>)}>
      <LazyRoute />
    </React.Suspense>
  )
}