import React, { useContext } from 'react';
import { TypeIcon } from './TypeIcon';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
// import { DhlImage } from '@dhl-official/react-library';
// import { IconContext } from '../../assets/IconContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ItemWrapper = styled.div`
  width: auto !important;
  width: 100%;
  max-height: 50px;
  display: flex;
  margin-top: 4px;
  padding: 8px;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
  align-items: center;
  &:hover {
    background-color: #f9f9f9;
    color: #d40511;
  }
`;

const LeftIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 4px;
  display: flex;
  flex-direction: row;
  align-items: end;
`;

const TextWrapper = styled.div`
  width: auto !important;
  display: flex;
  margin-top: 4px;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const StyledText = styled.text`
  font-size: 13px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
`;

// const LeftIcon = styled(DhlImage)`
//   background-size: 30px 30px;
//   border-radius: 15px;
// `;

const Item: React.FC<TypeIcon> = (props) => {
  const theme = useContext(ThemeContext);
  const { label, onSelect, icon = '' } = props;

  const onClick = (e: any): void => {
    if (onSelect !== undefined) onSelect();
  };

  return (
    <ItemWrapper theme={theme} onClick={onClick}>
      <LeftIconWrapper>
        <FontAwesomeIcon icon={icon} />
      </LeftIconWrapper>

      <TextWrapper>
        <StyledText theme={theme}>{label}</StyledText>
      </TextWrapper>
    </ItemWrapper>
  );
};

export default Item;
