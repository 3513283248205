import React, {useContext} from 'react';
import styled from 'styled-components';
import {ThemeContext} from '../../../styles/ThemeContext';
import {UserContext} from '../../../contexts/UserContext';
import {AvtarType} from './AvtartType';
import DhlText from '../../../components/DhlText';
// import {DhlImage} from "@dhl-official/ui-libraries/react-library";
// import {IconContext} from "../../../assets/IconContext";

const Wrapper = styled.div<{ isSmall: boolean }>`
  width: ${(props) => (props.isSmall ? '30px' : '150px')};
  height: ${(props) => (props.isSmall ? '30px' : '150px')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: ${(props) => (props.isSmall ? '15px' : '75px')};
  background-color: ${(props) => props.theme.color.grey[500]};
`;

const AvatarPicture = styled.img<{ $isSmall: boolean }>`
    height: ${(props) => (props.$isSmall ? '30px' : '150px')};
    width: ${(props) => (props.$isSmall ? '30px' : '150px')};
    border-radius: 50%;
    object-fit: cover;
`

// const EditPicture = styled(DhlImage)`
//     height: 40px;
//     width: 40px;
//     position: relative;
//     top: 30%;
//     left: -17%;
//     cursor: pointer;
// `

const Avtar: React.FC<AvtarType> = (props) => {
    const {theme} = useContext(ThemeContext);
    const {user} = useContext(UserContext);
    // const {getIcon} = useContext(IconContext);
    let MyPlaceHolder = "AB";

    if(user !== undefined || props.userName !== undefined) {
        MyPlaceHolder = `${Array.from(props.userName !== undefined ? props.userName.firstName : user.firstName)[0]}${
            Array.from(props.userName !== undefined ? props.userName.lastName : user.lastName)[0]
        }`;
    }


    if(MyPlaceHolder === "undefinedundefined") {
        MyPlaceHolder = "AB"
    }

    const {isSmall = false} = props;

    return (
        <>
            {(user.avatar == null || (props.noAvatar ?? false)) &&
                <Wrapper theme={theme} isSmall={isSmall}>
                    <DhlText
                        size={isSmall ? 'sm' : 'heading4'}
                        weight={800}
                        color={'#ffffff'}
                        title={MyPlaceHolder.toUpperCase()}
                    />
                </Wrapper>
            }
            {user.avatar != null && !(props.noAvatar ?? false) &&
                <AvatarPicture src={URL.createObjectURL(user.avatar)} $isSmall={isSmall}/>
            }

            {/* {!isSmall && <EditPicture src={getIcon('RedPencil')}/>} */}
        </>
    );
};

export default Avtar;
