export const errorMap = {
  1: {
    id: 'deactivate',
    type: 'error',
    title: 'App.Error.deactivatedTitle',
    subTitle: 'App.Error.deactivatedSubTitle',
    buttonText: 'App.common.reactivateAccount',
    image: 'deactivated',
  },
  2: {
    id: 'activate',
    type: 'success',
    title: 'App.Error.activatedTitle',
    subTitle: 'App.Error.activatedSubTitle',
    buttonText: 'App.common.loginToMysc',
    image: 'activated',
  },
  3: {
    id: 'logout',
    type: 'success',
    title: 'App.Error.activatedTitle2',
    subTitle: 'App.Error.activatedSubTitle2',
    buttonText: 'App.common.loginToMysc',
    image: 'activated2',
  },
  4: {
    id: 'notFound',
    type: 'error',
    title: 'App.Error.notFound.title',
    subTitle: 'App.Error.notFound.subTitle',
    buttonText: 'App.applicationName',
    image: 'deactivated',
  },
};
