import React, { useContext } from 'react';
import { TypeIcon } from './TypeIcon';
import { iconTypeEnum } from '.';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import {
  DhlIcon,
  DhlIconWrapper,
  DhlImage,
} from '@dhl-official/react-library';
import DhlText from '../DhlText';
import './MenuStyle.css';
import downArrowLight from '../../assets/images/downArrowLight.svg';
import { IconContext } from '../../assets/IconContext';

const ItemWrapper = styled.div`
  width: 100%;
  min-width: 150px;
  height: 30px;
  display: flex;
  margin-top: 1px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => props.theme.background};
  &:hover {
    background-color: ${(props) => props.theme.secondaryBg};
    color: '#d40511';
  }
`;

const LeftIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: end;
`;

const ItemIconRightBranding = styled(DhlIconWrapper)`
  margin-left: 10px;
  background-color: ${(props) =>
    props.theme.isBrandingObjectShowing === true
      ? props.theme.textColor
      : null};
  width: 15px;
  height: 15px;
  mask-size: 15px;
  mask-image: url(${(props) =>
    props.theme.isBrandingObjectShowing === true
      ? props.theme.imageUrl
      : null});
`;

const ItemIconRight = styled(DhlIconWrapper)`
  margin-left: 10px;
`;

const getBase64 = (icon: string): string => `data:image/png;base64,${icon}`;

const MenuItemLanguage: React.FC<TypeIcon> = (props) => {
  const theme = useContext(ThemeContext);
  const { getIcon } = useContext(IconContext);

  const {
    item,
    icon,
    type,
    label,
    onSelect,
    iconType,
    isBrandingObjectShowing,
    brandingTextColor,
  } = props;

  const onClick = (e: any): void => {
    if (onSelect !== undefined) onSelect(item);
  };
  const textColor = (): string => {
    if (
      isBrandingObjectShowing !== undefined &&
      isBrandingObjectShowing &&
      brandingTextColor !== undefined
    ) {
      return brandingTextColor;
    } else {
      return '#000000';
    }
  };

  const imageUrl = downArrowLight.substring(downArrowLight.indexOf(':'));

  return (
    <ItemWrapper theme={theme} onClick={onClick}>
      <LeftIconWrapper>
        {icon !== null &&
          <DhlImage
          alt={label}
          id={label}
          dataClassName="Icon"
          width={25}
          height={20}
          src={iconType === iconTypeEnum.BASE64 ? getBase64(icon) : icon}
        />
        }
        
      </LeftIconWrapper>

      <DhlText
        size={'sm'}
        weight={type !== 'options' ? 800 : 400}
        title={label}
        color={type !== 'options' ? textColor() : undefined}
        dataClassName={type !== 'options' ? 'Label' : undefined}
      />

      {type !== 'options' ? (
        isBrandingObjectShowing === true ? (
          <ItemIconRightBranding
            size="sm"
            theme={{
              ...theme,
              isBrandingObjectShowing,
              imageUrl,
              textColor,
            }}
          ></ItemIconRightBranding>
        ) : (
          <ItemIconRight size="sm">
            <DhlIcon src={getIcon('downArrow')} size="1rem" />
          </ItemIconRight>
        )
      ) : null}
    </ItemWrapper>
  );
};

export default MenuItemLanguage;
