import React, { createContext, useState, useEffect, useContext } from 'react';
import CommonType from './types/CommonType';
import ServiceRequest from '../service/ServiceRequest';
import { NOTIFICATION_TYPES, NotificationContext } from './NotificationContext';
import { LoadingContext } from './LoadingContext';
import CountryType from './types/CountryType';
import { LocalStorageHelper } from '../utils/LocalStorageHelper';

const LOCAL_STORAGE_COUNTRIES = 'LOCAL_STORAGE_COUNTRIES';

export const CountryContext = createContext({
  countries: [] as CountryType[],
});

const CountryProvider: React.FC<CommonType> = (props) => {
  const [countries, setCountriesData] = useState<CountryType[]>([]);
  const { setType, setMessage } = useContext(NotificationContext);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(true);
    const localCountries = LocalStorageHelper.getLocalResponseData(LOCAL_STORAGE_COUNTRIES);

    if (localCountries !== null) {
      setCountriesData(localCountries.data)
      setLoading(false);
    } else {
      ServiceRequest.getCountries()
      .then((response) => {
        setCountriesData(response.data);
        LocalStorageHelper.setLocalResponseData(LOCAL_STORAGE_COUNTRIES, response)
      })
      .catch((error) => {
        setType(NOTIFICATION_TYPES.FAILED);
        setMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, []);

  return (
    <CountryContext.Provider value={{ countries }}>
      {props.children}
    </CountryContext.Provider>
  );
};

export default CountryProvider;
